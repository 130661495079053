function form() {

    console.log('siup')

    $('form').on('submit', function(e) {
        // Prevent the form from actually submitting
        e.preventDefault()

        // Get the post data
        const data = $(this).serialize()

        // Send it to the server
        $.post('/', data, function(response) {
            if (response.success) {
                alert('Thanks, your message has been submitted!')
            } else {
                for (var key in response.error) {
                    console.log(key)
                    $(`[name='${key}']`).after(`<span class='form__error'>${response.error[key]}</span>`)
                }
            }
        })
    })
}

export default { form }