import CookieConsent from 'cookieconsent'

document.addEventListener('DOMContentLoaded', () => {
    cookieconsent.initialise({
        container: document.getElementById("content"),
        palette:{
            popup: {background: "#ececec"},
            button: {background: "#00abc1"},
        },
        content: {
            dismiss: 'Accept',
            message: 'This website uses cookies to ensure you get the best experience on our website.',
            link: 'Our privacy policy',
            href: '/about/privacy-policy'
        },
        revokable:true,
        onStatusChange: function(status) {
            console.log(this.hasConsented() ? 'enable cookies' : 'disable cookies')
        },
        law: {
            regionalLaw: false,
        },
        location: true,
    })
})