// if($('#support-form').length) {
//     $('#support-form').on('submit', function(e) {
//         e.preventDefault()
//         console.log('submitted')

//         $.ajax({
//             type: "POST",
//             xhrFields: {
//                 withCredentials: true
//             },
//             username: 'christine.targett@tbt-c.com',
//             password: 'hOYsZKrmqGb4jmEAmFa85ogqzEGADzweQVUNOwlT',
//             url: 'https://riviam.zendesk.com/api/v2/portal/requests.json',
//             data: '{"request": {"subject": "Help!", "comment": {"body": "My printer is on fire!"}}}',
//             dataType: 'application/json',
//             contentType: 'application/json',
//             beforeSend: function (xhr){ 
//                 xhr.setRequestHeader('Authorization', 'Bearer Y2hyaXN0aW5lLnRhcmdldHRAdGJ0LWMuY29tOmhPWXNaS3JtcUdiNGptRUFtRmE4NW9ncXpFR0FEendlUVZVTk93bFQ='); 
//             },
//             success: function(data){console.log(data)}
//         })
//     })
// }

// //hOYsZKrmqGb4jmEAmFa85ogqzEGADzweQVUNOwlT