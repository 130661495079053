// // Elements
// const $diagramCircle = $('.diagram__circle')
// const $diagram = $('.diagram')
// const $diagramHover = $('.diagram__hover')


// $diagramCircle.on('mouseover', function(e) {
//     // Get content & put it in the div
//     let diagramContent = $(this).attr('data-desc')
//     $diagramHover.find('p').html(diagramContent)

//     // Work out which circle is being hovered
//     let circleClasses = $(this).attr('class')
    
//     // Style the hover element based on the classes
//     circleClasses = circleClasses.split(' ')[1]
//     circleClasses = circleClasses.split('--')[1]
//     console.log(circleClasses)
//     $diagramHover.addClass(`diagram__hover--${circleClasses}`)

//     // Show the hover state
//     $diagramHover.addClass('is-active')
    
//     // Position the hover element near the mouse
//     $diagram.on('mousemove', function(e) {
//         handleMouseMove(e)
//     })
// }).on('mouseleave', function(e) {
//     // Hide the hover element
//     $diagramHover.attr('class', 'diagram__hover')

//     // Empty the content
//     $diagramHover.find('p').html('')
// })




// // Getting cursor position
// function handleMouseMove(event) {
//     var dot, eventDoc, doc, body, pageX, pageY;

//     event = event || window.event; // IE-ism

//     // If pageX/Y aren't available and clientX/Y are,
//     // calculate pageX/Y - logic taken from jQuery.
//     // (This is to support old IE)
//     if (event.pageX == null && event.clientX != null) {
//         eventDoc = (event.target && event.target.ownerDocument) || document;
//         doc = eventDoc.documentElement;
//         body = eventDoc.body;

//         event.pageX = event.clientX +
//           (doc && doc.scrollLeft || body && body.scrollLeft || 0) -
//           (doc && doc.clientLeft || body && body.clientLeft || 0)

//         event.pageY = event.clientY +
//           (doc && doc.scrollTop  || body && body.scrollTop  || 0) -
//           (doc && doc.clientTop  || body && body.clientTop  || 0)
//     }

//     $diagramHover.css({
//         left:  event.pageX,
//         top:   event.pageY
//     })
// }