function social() {
    if ($('.social-sharing').length > 0) {
        const $socialWindow = $('.js-social-window')

        $socialWindow.on('click', function(e) {
            console.log('click')
            e.preventDefault()
            window.open(this.href, "Social", "width=800, height=600")
            return false;
        })
    }
}

social()

export default { social }