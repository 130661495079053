// const $body = $('body'),
//     $hamburger = $('.menu__hamburger'),
//     $mask = $('.menu__mask'),
//     $checkbox = $('.menu__checkbox'),
//     $menuLink = $('.menu__link'),
//     $menuList = $('.menu__list'),
//     activeMenuClass = 'menu-is-active',
//     activeClass = 'is-active'

// // Hamburger click event
// $hamburger.on('click', function() {
//     if ($body.hasClass(activeMenuClass)) {
//         $body.removeClass(activeMenuClass)
//         setTimeout(function(){
//             $('.structure').removeClass(activeClass)
//         }, 1000)
//     } else {
//         $body.addClass(activeMenuClass)
//     }
// })

// // Link click event
// $menuLink.on('click', function(e) {
//     if ($(this).is('[data-parent]')) {
//         e.preventDefault()
//         const target = $(this).data('parent')
//         if ($(`.structure[data-structure="${target}"]`).hasClass(activeClass)) {
//             $(`.structure[data-structure="${target}"]`).removeClass(activeClass)
//             if ($(window).width() < 767) {
//                 $body.removeClass(activeMenuClass)
//             }
//         } else {
//             $(`.structure[data-structure="${target}"]`).addClass(activeClass)
//             if ($(window).width() < 767) {
//                 $body.addClass(activeMenuClass)
//             }
//         }
//     } else {
//         $body.removeClass(activeMenuClass)
//         $checkbox.prop('checked', false)
//         $menuLink.removeClass(activeClass)
//         $(this).addClass(activeClass)
//     }
// })

// // Mask click event
// $mask.on('click', function() {
//     if ($body.hasClass(activeMenuClass)) {
//         $body.removeClass(activeMenuClass)
//         $checkbox.prop('checked', false)
//     } else {
//         $body.addClass(activeMenuClass)
//     }
// })


// // Sticky header
// const $header = $('.header'),
//     $window = $(window),
//     stickyClass = 'is-sticky',
//     top = $('body').offset().top + 100

// if ($window.scrollTop() >= top) {
//     $header.addClass(stickyClass)
// }

// $window.scroll(function() {
//     if ($window.scrollTop() >= top) {
//         $header.addClass(stickyClass)
//     } else {
//         $header.removeClass(stickyClass)
//     }
// })


import {
    TweenMax, TimelineLite
} from "gsap"

// Misc elements
const $body = $('body')
const $siteMask = $('.site-mask')
const $search = $('.search')

// Mobile menu elements
const $checkbox = $('.menu__checkbox')
const $hamburger = $('.menu__hamburger')

// Menu elements
const $menu = $('.menu__wrapper')
const $menuLink = $('.menu__link')
const $menuList = $('.menu__list')

// Child menu elements
const $submenu = $('.menu__subwrap')

// Classes
const activeMenuClass = 'is-visible'
const bodyMenuClass = 'is-hidden'
const activeClass = 'is-active'
const menuClass = 'menu-is-active'

// GSAP setup
const tl = new TimelineLite()
const submenuTl = new TimelineLite()

function closeMenu() {
    tl
        .to($menu, .4, {
            x: '110%',
            ease: Expo.easeInOut,
            force3D:true
        })
    $body.removeClass(bodyMenuClass)
    $menu.removeClass(activeClass)
    $submenu.removeClass(activeClass)
    $hamburger.removeClass(activeClass)
    //$search.removeClass(menuClass)

}

function openMenu() {
    $body.addClass(bodyMenuClass)
    $menu.addClass(activeClass)
    $hamburger.addClass(activeClass)
    //$search.addClass(menuClass)

    tl
        .to($menu, .4, {
            x: '0%',
            ease: Expo.easeInOut,
            force3D:true
        })
}

// Hamburger click event
$hamburger.click(function() {
    if ($body.hasClass(bodyMenuClass)) {
        closeMenu()
    } else {
        openMenu()
    }
})

// Open child menu
$('[data-parent]').on('click', '[data-more]', function(e) {
    if ($(window).width() < 969) {
        e.preventDefault()

        // Get the target menu
        const target = $(this).parent().attr('data-parent')
        
        // Show the target item
        $(`[data-target='${target}']`).addClass(activeClass)
    }
})

// Menu link click event
$menuLink.on('click', function(e) {

    // Make clicked link the active one
    if (!$(this).attr('target')) {
        $menuLink.removeClass(activeClass)
        $(this).addClass(activeClass)
    }

    // Remove the mobile body tag
    if ($(window).width() < 979) {
        closeMenu()
    }
})

// Go back in mobile menu
$('[data-back]').on('click', function() {
    $(this).closest('.menu__subwrap').removeClass(activeClass)
})


const delay = 150
let setTimeoutConst
const delay2 = 150
let setTimeoutConst2

if ($(window).width() > 979) {
    
    $('.menu__item--main[data-parent]').on('mouseover', function() {
        const $this = $(this)
        setTimeoutConst = setTimeout(function() {
            $this.addClass('active-menu')
        },delay)
        
    }).on('mouseleave', function() {
        const $this = $(this)
        clearTimeout(setTimeoutConst)
        setTimeoutConst2 = setTimeout(function(){
            const isHover = $('.menu__item--main').is("hover")
            if(isHover !== true){
                $this.removeClass('active-menu')
            }
        },delay2)
    })
}


// Reset the wrapper positioning when changing window size
$(window).resize(function() {
    $menu.attr('style', '')
})