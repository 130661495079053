window.require = require

import menu from './default/menu'
import form from './default/form'
import webfontLoader from './default/webfont-loader'
import social from './default/social'
//import parallax from './default/parallax'


import diagram from './modules/diagram'
import newsletter from './modules/newsletter-signup'
import filtering from './modules/filtering'
import support from './modules/support-form'
import cookies from './modules/cookies'


// Barba.JS
// import pageTransitions from './default/pageTransitions'