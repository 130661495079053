// if ($('.js-search-form').length) {
    
//     // elements
//     const $filterField = $('[data-filter]')
//     const $searchForm = $('.js-search-form')

//     // insert parameter function
//     function insertParam(key, value){
//         key = encodeURI(key); value = encodeURI(value);

//         var kvp = document.location.search.substr(1).split('&');

//         var i=kvp.length; var x; while(i--) 
//         {
//             x = kvp[i].split('=');

//             if (x[0]==key)
//             {
//                 x[1] = value;
//                 kvp[i] = x.join('=');
//                 break;
//             }
//         }

//         if(i<0) {kvp[kvp.length] = [key,value].join('=');}

//         //this will reload the page, it's likely better to store this until finished
//         document.location.search = kvp.join('&'); 
//     }
    
//     // On filter items change
//     // $filterField.on('change', function(e) {
//     //     const type = $(this).attr('data-filter')
//     //     const value = $(this).val()
        
//     //     insertParam(type, value)
//     // })

//     // on form submit
//     $searchForm.on('submit', function(e) {
//         if (!$('[data-filter="cat"]').val() == '') {
//             const value = $(this).val()
//             insertParam('cat', value)
//         }
//     })
// }

